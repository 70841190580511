import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import SEO from '../components/seo';
import TimeTable from '../components/timetable';
import kidsSchedule from '../data/kids_schedule.json';
import adultSchedule from '../data/class_schedule.json';
import { filterLessons } from '../tools/utils';

const HillsideHall = ({ data, location }) => {
  const lessons = filterLessons(
    [...adultSchedule, ...kidsSchedule],
    'hillside hall',
  );
  return (
    <>
      <SEO
        title="Hillside Hall"
        keywords={[`hillside hall`, `dance`, `lessons`, `children`, `kids`]}
        location={location}
      />
      <Link
        to="/lessons#schedule"
        className="hover:underline text-highlight font-semibold mb-4"
      >
        &larr;Back to Class Schedule
      </Link>
      <article className="flex flex-col items-center md:flex-row md:items-start md:justify-around">
        <div className="text-justify">
          <h2>Hillside Hall</h2>
          <p>
            Hillside Hall
            <br />
            Hillside Road
            <br /> Bath
            <br /> BA2 3NX
          </p>
        </div>

        <figure className="mt-5 w-4/5 md:w-1/3 md:mt-0 self-center">
          <Image
            className="rounded"
            fluid={data.venue.sharp.fluid}
            alt="Hillside Hall"
          />
          <figcaption className="text-xs">Hillside Hall.</figcaption>
        </figure>
      </article>
      <section className="md:mx-auto">
        <h2 className="mt-4">Class Times</h2>
        <TimeTable data={lessons} />
      </section>
    </>
  );
};

export const query = graphql`
  query {
    venue: file(relativePath: { eq: "hillside-hall.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default HillsideHall;